<template>
    <div class="box_1">
      <div class="form_login">
        <div style="display: flex; justify-content: flex-start; align-content: center; align-items: center;" >
            <img src="@/assets/icons/retextoar-app.png" width="35px" height="35px"/>
            <p class="titleStyle">Retextoar</p>
        </div>
        <form @submit.prevent="handleSubmit" class="form-group" v-show="codeValid">
            <b-form-group>
            <b-form-input
              id="user-name"
              v-model="user.name"
              :class="{ 'is-invalid': submitted && $v.user.name.$error }"
              placeholder="Nome do Usuário"
              class="inputText"
            ></b-form-input>
            <div
              v-show ="submitted && $v.user.name.$error"
              class="invalid-feedback"
            >
              <span v-show="!$v.user.name.required"
                >Nome do Usuário é obrigatório.</span
              >
            </div>
          </b-form-group>
          <b-form-group>
            <b-form-input
              id="pass-input"
              type="password"
              v-model="user.password"
              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
              placeholder="Senha"
              class="inputText"
            ></b-form-input>
            <div
              v-show ="submitted && $v.user.password.$error"
              class="invalid-feedback"
            >
              <p v-show="!$v.user.password.required"
                >Senha é obrigatória.</p
              >
              <span v-show ="!$v.user.password.minLength">Senha deve ter pelo menos 8 caracteres.</span>
              <span v-show="user.password && !$v.user.password.valid">
                <div class="password-rules">
                  <ul class="password-rules-list">
                    <li class="password-digit">Pelo menos um número.</li>
                    <li class="password-lower-case">Pelo menos um caractere minúsculo.</li>
                    <li class="password-upper-case">Pelo menos um caractere maiúsculo.</li>
                    <li class="password-special-character">Pelo menos um caractere especial (!@#()-[{}]:;',?/*~$^+=.`|%).</li>
                  </ul>
                </div>
              </span>
            </div>
          </b-form-group>
          <b-form-group>
            <b-form-input
              type="password"
              v-model="user.confirmPassword"
              id="confirmPassword"
              name="confirmPassword"
              class="inputText"
              placeholder="Confirme senha"
              :class="{
                'is-invalid': submitted && $v.user.confirmPassword.$error,
              }"
            />
            <div
              v-if="submitted && $v.user.confirmPassword.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.user.confirmPassword.required"
                >Confirmação de senha é obrigatória.</span
              >
              <span v-else-if="!$v.user.confirmPassword.sameAsPassword"
                >Senhas devem ser iguais</span
              >
            </div>
          </b-form-group>
          <div>
            <b-checkbox v-model="checked" class="label-class">
              Concordar com o
              <b-link target="_blank" href="https://www.retextoar.com.br/termo-de-uso-e-privacidade/" class="label-class" v-text="term"/>
            </b-checkbox>
          </div>
          <div style="padding-bottom: 0px !important; margin-top: 15px !important;">
            <vue-recaptcha
                    class="g-recaptcha"
                    @verify=onVerify()
                    @expired=onExpired()
                    ref="recaptcha"
                    sitekey="6LclwE4fAAAAAA2ng7_J2HYYW34CHDgHjKLzucPA">
            </vue-recaptcha>
          </div>
          <div class="submit-button">
            <b-button class="newMonitorButton2" :disabled="enableSubmit()" type="submit">Enviar</b-button>
          </div>
        </form>
        <div class="text-container" v-show="!codeValid">
          <p class="text-content" style="color: rgb(210,5,5);">Usuário já possui cadastro no sistema!</p>
          <p class="text-content">Redirecionando para o login em {{timer}}.</p>
        </div>
      </div>
        <Carousel></Carousel>
    </div>
</template>

<!-- Then load vue-recaptcha -->
<script src="https://unpkg.com/vue-demi@0.12.1/lib/index.iife.js"></script>

<!-- Then load vue-recaptcha -->
<script src="https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.js"></script>
<!-- Minify -->
<script src="https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.min.js"></script>

<script>

  import { required, minLength, sameAs } from 'vuelidate/lib/validators';
  import { userService } from '../services/user_service';
  import {VueRecaptcha} from "vue-recaptcha";
  import Carousel from './Login/Carousel.vue';
  export default {
    name: 'Home',
    components: {
      'vue-recaptcha': VueRecaptcha,
      Carousel
    },
    data () {
      return {
        codeValid: true,
        timer: 6,
        captchaVerify: false,
        user: {
          name: '',
          password: '',
          confirmPassword: '',
          login: ''
        },
        checked: false,
        term: ' termo de uso e privacidade',
        submitted: false
      };
    },
    validations: {
      user: {
        name: { required },
        password: {
          required,
          valid: function (value) {
            const containsUppercase = /[A-Z]/.test(value);
            const containsLowercase = /[a-z]/.test(value);
            const containsNumber = /[0-9]/.test(value);
            const containsSpecial = /[#?!+@:;$%^&*-]/.test(value);
            return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial
            );
          },
          minLength: minLength(8)
        },
        confirmPassword: { required, sameAsPassword: sameAs('password') }
      }
    },
    async created () {
      if (this.$route.query.redeem) {
        const result = await userService.redeemCode(this.$route.query.redeem);
        if (result.statusText === 'Forbidden') {
          this.codeValid = false;
          setTimeout(() => {
            this.$router.push({ path: 'login' });
          }, 6000);
          setInterval(() => { this.timer--; }, 1000);
        } else if (result.isCodeValid) {
          this.user.login = result.login;
          this.codeValid = true;
        } else {
          this.codeValid = false;
        }
      } else {
        console.log('Not good');
      }
    },
    methods: {
      onVerify (response) {
        this.captchaVerify = true;
      },
      onExpired () {
        this.captchaVerify = false;
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset()
      },
      enableSubmit () {
        if (this.captchaVerify && this.checked) {
          return false;
        }
        else {
          return true;
        }
      },
      async handleSubmit (event) {
        event.preventDefault();
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        await userService.register(this.user);
        this.$toast.open({ message: 'Usuário cadastrado com sucesso!', duration: 3000 });
        setTimeout(() => {
          this.$router.push({ path: 'login' });
        }, 3500);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .box_1 {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;
        background: #ffff;
        height:100vh !important ;
        margin: 0;
        margin-left: 200px;
    }
    .form_login {
        display: flex;
        flex-direction: column;
        background: #ffff;
        top: 30%;
    }

    #login-input{
      height: 53px;
      font-weight: 400;
      font-size: 14px;
    }
    .titleStyle {
        font-size: 28px;
        font-weight: bold;
        color: #153252;
        margin-left: 10px;
        margin-top: 10px;
    }

    .inputText {
        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 16px 12px;
        gap: 12px;
        width: 368px;
        font-weight: 400;
        font-size: 14px;

        height: 53px;
        left: 0%;
        right: 0%;
        top: calc(50% - 53px/2 + 6px);

        /* branco */
        background: #FFFFFF;

        /* cinza bordas */
        border: 1px solid #E6ECF2;
        border-radius: 4px;
    }

.invalid-feedback {
  margin-top: 0.1rem !important;
  font-size: 11px;
}

.password-rules-list {
 margin-bottom:2px;
}
.password-rules-list li {
 display:flex;
}

.g-recaptcha {
 transform:scale(0.77);
  -webkit-transform:scale(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
  margin-bottom: 0px;
}

.newMonitorButton2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 12px 32px;
        gap: 10px;
        margin-left: 265px;
        margin-top: 20px;
        width: 105px;
        height: 45px;
        font-weight: 500;
        font-size: 14px;

        background: #207BDD;
        border-radius: 4px;
        border-color: transparent;

        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
    }

</style>
